<template>
  <section class="addressen part">
    <div class="constrain">
      <div class="row-12">
        <div class="col-12 md:col-10 md:offset-1">
          <div class="row-10">
            <div
              v-for="address in payload.addressen"
              :key="address.id"
              v-view
              class="col-12 md:col-5 address animate in-bottom"
            >
              <span class="title">{{ address.name }}</span>
              <span class="address" v-html="address.addresse" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  props: { payload: { type: Object, default: () => ({}) } },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_mixin.scss";

.addressen {
  @apply text-primary;
  @apply font-serif;
  @apply text-sm;
  @include responsive('margin-top', px(51), px(156));
  @include responsive('line-height', px(22), px(36));

  @screen md {
    @apply text-base;
  }
}
.addressen:last-of-type {
  @include responsive('margin-bottom', px(51), px(156));
}

.address:not(:last-child) {
  @include responsive('margin-bottom', px(55), 0);
}

.title {
  display: inline-block;
  @include responsive('margin-bottom', 1.5rem, px(35));
}
</style>
